<template>
  <b-card>
    <b-overlay :show="loading">
      <b-row class="mb-2">
        <b-col cols="4">
          Status:
          <b-badge :variant="status.warna">
            {{ status.nama_status }}
          </b-badge>
        </b-col>
        <b-col cols="4">
          Tanggal Ajuan:
          <b-badge variant="light-info">
            {{ konsumen.tanggal }}
          </b-badge>
        </b-col>
        <b-col cols="4">
          Kondisi:
          <b-badge variant="light-primary">
            {{ konsumen.kondisi_ket }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" sm="12">
          <strong class="d">Nama Toko</strong>
          <p>
            {{ konsumen.nama_toko }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Nama Owner</strong>
          <p>{{ konsumen.nama_owner }}</p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Bidang Bisnis</strong>
          <p>
            {{ konsumen.bisnis_fokus }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">No. HP Konsumen</strong>
          <p>
            {{ konsumen.no_hp }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Alamat</strong>
          <p>
            {{ konsumen.alamat }}
          </p>
        </b-col>

        <b-col md="4" sm="12">
          <strong class="d-block">Jenis Konsumen</strong>
          <p>
            {{ konsumen.type_konsumen ? konsumen.type_konsumen.nama : "-" }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Bangunan</strong>
          <p>
            {{ konsumen.tipe_bangunan }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Kepemilikan Bangunan</strong>
          <p>
            {{ konsumen.kepemilikan_bangunan }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Lama Sewa Bangunan</strong>
          <p>
            {{ konsumen.lama_sewa }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Keterangan</strong>
          <p>
            {{ konsumen.keterangan }}
          </p>
        </b-col>
      </b-row>

      <b-row class="justify-content-end" v-if="canUpdateStatus">
        <b-col md="4" sm="12">
          <footer class="d-flex justify-content-end align-items-center">
            <b-form-select :options="statusOptions" class="mr-2" v-model="selectedStatus"></b-form-select>
            <b-button variant="primary" @click.prevent="update">Update Status</b-button>
          </footer>
        </b-col>
      </b-row>
      <!-- <div v-if="availableStatus.length > 0" class="d-flex justify-content-end">
        <b-button
          class="mr-1"
          v-for="(status, i) in availableStatus"
          :key="i"
          size="sm"
          @click.prevent="update(status.id)"
          :variant="status.warna"
        >
          {{ status.nama_status }}
        </b-button>
      </div> -->
    </b-overlay>
  </b-card>
</template>
<script>
import { BCard, BRow, BFormSelect, BCol, BFormGroup, BBadge, BButton, BOverlay } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BFormSelect,  
    BRow,
    BCol,
    BFormGroup,
    BBadge,
    BButton,
    BOverlay,
  },
  computed: {
    canUpdateStatus() {
      return this.konsumen && this.konsumen.karyawan && (this.konsumen.karyawan.id != this.user.karyawan.id)
    }
  },
  methods: {
    async update() {
      this.konsumen.status = this.selectedStatus.id ? this.selectedStatus.data_status : this.selectedStatus;
      this.loading = true;
      try {
        const updateAjuan = await this.$store.dispatch("ajuankonsumen/save", [
          this.konsumen,
        ]);

        const key = 'ajuan_konsumen'
        const value = {
          level: this.user.level.id,
          data: updateAjuan
        }

        this.eventSocket(key, value)

        this.loading = false;
        this.displaySuccess({
          message: "Ajuan berhasil diperbaharui",
        });
        setTimeout(() => {
          this.$router.push("/ajuankonsumen");
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
      }
    },
    async getStatus() {
      this.statusOptions = await this.$store.dispatch("statusrole/getData");
      this.status = this.statusOptions.find((st) => st.id == this.konsumen.status);
    },
  },
  data: () => ({
    selectedStatus: null,
    loading: false,
    konsumen: null,
    availableStatus: [],
    statusOptions: [],
    status: {
      warna: "variant",
      nama_status: "pending",
    },
  }),
  async created() {
    // get status
    const currMenu = await this.currentMenu(this.$route.meta.parent)

    const statusOptions = await this.$store.dispatch("statusrole/getData", {
        menu_id: currMenu.id
    })
    const validStatus = []
    statusOptions.map(status => {
      const find = validStatus.find(v_status => v_status.data_status == status.data_status)

      if(!find) {
        status.value = status.data_status
        status.text = status.nama_status
        validStatus.push(status)
      }
    })

    if (!validStatus || validStatus.length < 1) {
      this.$router.push("/ajuankonsumen");
    }
    this.statusOptions = validStatus;
    const konsumens = this.$store.state.ajuankonsumen.datas;
    this.konsumen = konsumens.find((konsumen) => konsumen.id == this.$route.params.id);

    if (!this.konsumen) {
      this.$router.push("/ajuankonsumen");
    }
    // assign status
    const findStatus = this.statusOptions.find((status) => status.data_status == this.konsumen.status);
    if(findStatus) {
      this.konsumen.status = findStatus
    }
  },
};
</script>
